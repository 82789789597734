import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import { StaticImage } from "gatsby-plugin-image";

import HeaderTypography from "./Typography/HeaderTypography";
import ParagraphTypography from "./Typography/ParagraphTypography";
import WhiteButton from "./WhightButton";
import NotFoundPage from "../../pages/404";

import requestDemoBGImg from "../../images/v2/case-studies/requestDemo.svg";
import featureBG from "../../images/v2/case-studies/featuresBG.svg";

import useWidth from "../../hooks/useWidth";

import { Link } from "gatsby";

import case1hero from "../../images/v2/case-studies/case-1-hero.png";
import case2hero from "../../images/v2/case-studies/case-2-hero.png";
import case3hero from "../../images/v2/case-studies/case-3-hero.png";
import case4hero from "../../images/v2/case-studies/case-4-hero.png";
import case5hero from "../../images/v2/case-studies/case-5-hero.png";
import case6hero from "../../images/v2/case-studies/case-6-hero.png";

import access from "../../images/v2/case-studies/Access.svg";
import calendar from "../../images/v2/case-studies/Calendar.svg";
import dashboard from "../../images/v2/case-studies/Dashboard.svg";
import enterprise from "../../images/v2/case-studies/Enterprise.svg";
import lead from "../../images/v2/case-studies/Lead.svg";
import mailchimp from "../../images/v2/case-studies/Mailchimp.svg";
import message from "../../images/v2/case-studies/Message.svg";
import messenger from "../../images/v2/case-studies/Messenger.svg";
import office365 from "../../images/v2/case-studies/Office365.svg";
import pipeline from "../../images/v2/case-studies/Pipeline.svg";
import quotation from "../../images/v2/case-studies/Quotation.svg";
import webform from "../../images/v2/case-studies/Webforms.svg";
import whatsapp from "../../images/v2/case-studies/Whatsapp.svg";
import xero from "../../images/v2/case-studies/Xero.svg";
import CircularProgress from "@material-ui/core/CircularProgress";

import SEO from "../SEO";

const useStyles = makeStyles((theme) => ({
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  featureBG: {
    background: `url(${featureBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  logo: {
    width: "54px",
    height: "54px",
  },
  featureItem: {
    borderLeft: "1px solid",
    borderRadius: theme.shape.borderRadius * 2.5,
    borderColor: "#e2f0ff",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      borderLeft: "unset",
    },
  },
  aboutList: {
    position: "sticky",
    top: "80px",
    display: "flex",
    justifyContent: "row",
  },
  aboutListIndicator: {
    background: "#ef600d",
    width: "2px",
    height: "100px",
    "&:after": {
      content: '""',
      top: "20px",
      width: "2px",
      zIndex: -1,
      height: "500px",
      position: "absolute",
      background: "#d3d3d3",
    },
  },
  readMoreLink: {
    color: "#ff7a59",
    textDecoration: "underline",
    fontSize: "16px",
    padding: "16px 8px",
  },
}));

const Company = () => {
  const location = useLocation();
  const [state, setState] = useState({
    loading: true,
    company: null,
  });

  const { loading, company } = state;

  const classes = useStyles();
  const width = useWidth();

  const names = {
    "eng-choon-enterprise": (
      <StaticImage
        alt="Eng Choon Enterprise Pte. Ltd"
        fit="cover"
        height={70}
        layout="constrained"
        placeholder="blured"
        src="../../images/v2/case-studies/case-1-name.png"
      />
    ),
    "the-supreme": (
      <StaticImage
        alt="The Supreme HR Advisory Pte. Ltd."
        fit="cover"
        height={70}
        placeholder="blured"
        layout="constrained"
        src="../../images/v2/case-studies/case-2-name.png"
        width={236}
      />
    ),
    dreamcation: (
      <StaticImage
        alt="Dreamcation"
        fit="cover"
        height={52}
        layout="constrained"
        placeholder="blured"
        src="../../images/v2/case-studies/case-3-name.png"
        width={208}
      />
    ),
    "skypac-packaging": (
      <StaticImage
        alt="SKYPAC Packaging Pte. Ltd."
        fit="cover"
        height={60}
        layout="constrained"
        placeholder="blured"
        src="../../images/v2/case-studies/case-4-name.png"
        width={156}
      />
    ),
    "tech-data": (
      <StaticImage
        alt="Tech Data"
        fit="cover"
        height={44}
        layout="constrained"
        placeholder="blured"
        src="../../images/v2/case-studies/case-5-name.png"
        width={228}
      />
    ),
    cuscapi: (
      <StaticImage
        alt="Cuscapi"
        fit="cover"
        height={60}
        layout="constrained"
        placeholder="blured"
        src="../../images/v2/case-studies/case-6-name.png"
        width={214}
      />
    ),
  };

  const titles = {
    "eng-choon-enterprise":
      "Eng Choon Enterprises is connecting with its customers across various channels",
    "the-supreme": "The Supreme HR Advisory is closing more leads faster",
    dreamcation:
      "Dreamcation is reducing its response time with Pepper Cloud CRM",
    "skypac-packaging": "Pepper Cloud CRM helps SKYPAC scale up its business",
    "tech-data":
      "Tech Data is managing leads and sales effectively with Pepper Cloud CRM",
    cuscapi:
      "Cuscapi is formulating its sales growth with Pepper Cloud sales CRM",
  };

  const successStories = {
    "eng-choon-enterprise": {
      icon: names["eng-choon-enterprise"],
      title: titles["eng-choon-enterprise"],
      link: "/crm-case-studies/eng-choon-enterprise",
    },
    "the-supreme": {
      icon: names["the-supreme"],
      title: titles["the-supreme"],
      link: "/crm-case-studies/the-supreme",
    },
    dreamcation: {
      icon: names["dreamcation"],
      title: titles["dreamcation"],
      link: "/crm-case-studies/dreamcation",
    },
    "skypac-packaging": {
      icon: names["skypac-packaging"],
      title: titles["skypac-packaging"],
      link: "/crm-case-studies/skypac-packaging",
    },
    "tech-data": {
      icon: names["tech-data"],
      title: titles["tech-data"],
      link: "/crm-case-studies/tech-data",
    },
    cuscapi: {
      icon: names["cuscapi"],
      title: titles["cuscapi"],
      link: "/crm-case-studies/cuscapi",
    },
  };

  const caseStudies = {
    "eng-choon-enterprise": {
      seo: {
        title:
          "WhatsApp CRM Case Studies - Eng Choon Enterprise | Pepper Cloud",
        keywords: "WhatsApp CRM Case Studies,WhatsApp CRM, Message management, CRM Case Studies",
        description:
          "Eng Choon Enterprise Pte Ltd improved sales by connecting customers across various channels using Pepper Cloud's WhatsApp CRM Integration. How? Learn from CRM Case Study",
      },
      title: titles["eng-choon-enterprise"],
      description:
        "Eng Choon Enterprise Pte. Ltd. (a.k.a. Aircon Uncle) is a B2C organisation that offers sales and installation of new aircon units, aircon servicing, and maintenance. It was incorporated in 2013 and has been a one-stop solution for all air quality needs since then.",
      heroBg: case1hero,
      alt: "Eng Choon Enterprise Pte. Ltd.",
      name: names["eng-choon-enterprise"],
      location: "Singapore",
      industry: "Cleaning services",
      website: "https://www.airconuncle.sg/",
      challenges: [
        "Aircon Uncle receives hundreds of queries and messages from its prospects and customers. The messages would be received through multiple communication channels such as Facebook, WhatsApp, etc. This meant that the sales, operation, and support teams had to respond to messages across various channels.",
        "The hassle of replying to messages across multiple platforms led to confusion within the team, delayed follow-ups during the critical stages of the sales process, a lack of transparency and visibility among the team members, and loss of sales opportunities.",
        "To resolve these issues and to prevent potential customers getting lost, Aircon Uncle needed a robust CRM that integrates with various channels and helps in effectively managing the sales conversations. ",
      ],
      challegeQuotation: (
        <strong>
          "We needed a dynamic tool that helps manage customer queries
          efficiently"
        </strong>
      ),
      challengeImg: (
        <StaticImage
          alt="Eng Choon Enterprise's Challenge Before Pepper Cloud CRM"
          layout="constrained"
          placeholder="blured"
          src="../../images/v2/case-studies/case-1-challenge.png"
        />
      ),
      solutionHeader:
        "Pepper Cloud CRM, being a WhatsApp CRM, eased the entire workflow for Aircon Uncle while reducing the turnover time drastically.",
      solutions: [
        "WhatsApp-integrated Pepper Cloud CRM allowed the team to respond to messages from within the CRM, without switching between apps.",
        "The team could reply on time with personalised, error-free message templates with the help of WhatsApp CRM. This helped the team to keep the conversation going and ensure no opportunities were missed.",
        "The management team gained complete visibility into the enquiries and could monitor the conversations between the leads and the team. This helped in redirecting the sales activities and training the personnel appropriately.",
        "The team members could assign the conversations to the other members. The easy assignment of messages between teams offered better collaboration between the sales, operations, and support teams.",
      ],
      solutionQuotation: (
        <strong>
          "We are now able to convert leads faster and reduce drop-offs"
        </strong>
      ),
      solutionImg: (
        <StaticImage
          alt="How Pepper Cloud CRM solved Supreme HR Advisory's Problem"
          layout="constrained"
          placeholder="blured"
          src="../../images/v2/case-studies/case-1-solution.png"
        />
      ),
      solutionFooter: "",
      featuresUsed: [
        {
          icon: whatsapp,
          title: "WhatsApp integration",
          link: "/whatsapp-crm",
        },
        { icon: message, title: "Message management", link: "" },
        {
          icon: quotation,
          title: "Quotation management",
          link: "/xero",
        },
      ],
      similarSuccessStories: [
        successStories["the-supreme"],
        successStories["dreamcation"],
        successStories["skypac-packaging"],
      ],
    },
    "the-supreme": {
      seo: {
        title: "Sales Pipeline Management Case Studies - Supreme HR | Pepper Cloud",
        description:
          "The Supreme HR Advisory is closing more sales leads faster using the sales pipeline management feature of Pepper Cloud CRM. How? Learn from CRM Case Study",
        keywords: "Sales Pipeline Management Case Studies,sales pipeline, Sales CRM, CRM Case Studies",
      },
      title: titles["the-supreme"],
      description:
        "The Supreme HR Advisory Pte. Ltd. is a recruitment agency that provides an efficient, effective and enjoyable staffing experience to job-seekers and employers since 2014.",
      heroBg: case2hero,
      alt: "The Supreme HR Advisory",
      name: names["the-supreme"],
      location: "Singapore",
      industry: "Recruitment agency",
      website: "https://www.thesupremehr.com/",
      challenges: [
        "The Supreme HR Advisory was able to generate leads easily. However, the team collected lead details in multiple spreadsheets and it was difficult to keep these spreadsheets updated all the time. It was confusing, hard to access and different versions of spreadsheets would pile up on the shared devices. This made the whole process cumbersome. As a result, there were delayed follow-ups and loss of opportunities and the process of tracking the team performance became time-consuming and tedious. The team soon realised their requirement.",
        "The Supreme HR Advisory needed a sales CRM solution that integrates with emails and is simple and easy to use for their salespeople.",
      ],
      challegeQuotation: (
        <strong>
          "We needed a sales-focused CRM that helps our business grow"
        </strong>
      ),
      challengeImg: (
        <StaticImage
          alt="The Supreme HR Advisory's Challenge Before Pepper Cloud CRM"
          layout="constrained"
          placeholder="blured"
          src="../../images/v2/case-studies/case-2-challenge.png"
        />
      ),
      solutionHeader:
        "Pepper Cloud CRM helped The Supreme HR Advisory in solving their problems by providing them with a single comprehensive platform.",
      solutions: [
        "Pepper Cloud CRM made it easier for the company to document their leads and sales data effortlessly at one easily accessible, central location. Furthermore, move the leads forward through the sales pipeline without any hassle.",
        <div>
          With <Link to="/crm-email-inbox-integration">CRM Office 365 integration</Link>, the emails
          could synchronise two-way and the emails could be sent and viewed
          directly from Pepper Cloud CRM.
        </div>,
        "Calendar and drive management became easier with CRM Outlook integration.",
        "Pepper Cloud CRM provided a complete overview of the leads in the sales pipeline and the status of the sales deals. The easy access to key information boosted the sales productivity of the team.",
        "The reporting and real-time dashboards of the CRM allowed the managers to stay up to date with what their team is working on, without constantly chasing for updates.",
        "The Supreme HR Advisory wanted to onboard every member of the organisation to use the CRM solution without increasing the monthly recurring cost. Our Enterprise plan helped them to achieve this.",
      ],
      solutionQuotation: (
        <strong>
          "The user interface is easy and our sales process is now more
          organised"
        </strong>
      ),
      solutionImg: (
        <StaticImage
          alt="How Pepper Cloud CRM solved Supreme HR Advisory's Problem"
          layout="constrained"
          placeholder="blured"
          src="../../images/v2/case-studies/case-2-solution.png"
        />
      ),
      solutionFooter: "",
      featuresUsed: [
        {
          icon: pipeline,
          title: "Sales pipeline management",
          link: "/sales-pipeline-management-tool",
        },
        {
          icon: enterprise,
          title: "Enterprise plan",
          link: "/crm-platfrom-pricing",
        },
        {
          icon: office365,
          title: "Office 365 integration",
          link: "/crm-email-inbox-integration",
        },
      ],
      similarSuccessStories: [
        successStories["dreamcation"],
        successStories["skypac-packaging"],
        successStories["tech-data"],
      ],
    },
    dreamcation: {
      seo: {
        title: "Facebook Messenger Integration Case Studies - Dreamcation | Pepper Cloud",
        description:
          "Dreamcation reduced its response time with Pepper Cloud CRM's Facebook Messenger Integration. How? Learn from CRM Case Study",
        keywords: "Facebook Messenger Integration Case Studies,Facebook messenger integration, CRM Case Studies",
      },
      title: titles["dreamcation"],
      description:
        "Dreamcation Cruises and Tours is a leisure travel agency in Singapore that provides halal tours for those who love to explore. The services include tours, cruises, free and easy, MICE and incentive, as well as private tours.",
      heroBg: case3hero,
      alt: "Dreamcation",
      name: names["dreamcation"],
      location: "Singapore",
      industry: "Travel agency",
      website: "https://www.dreamcation.com.sg/",
      challenges: [
        "Dreamcation used to receive a vast number of queries through Facebook and they struggled to respond to all the queries on time. As a result, many leads and opportunities slipped away. Additionally, Dreamcation had no single point of access to manage its deliverables, sales data, leads, and other key details. The bits of information were scattered across multiple platforms. The lack of cohesion and visibility affected efficiency and productivity. These made it difficult to move leads through the pipeline resulting in the loss of deals and missed opportunities. It caused major disruption in sales growth.",
        "Dreamcation started looking out for a Facebook CRM tool which is customisable and scalable solution. They needed a CRM for Facebook messenger that assists in consolidating the queries, reducing the response time, and capturing lead details effortlessly.",
      ],
      challegeQuotation: (
        <strong>
          "We took longer time to respond to queries and the leads would just
          get lost"
        </strong>
      ),
      challengeImg: (
        <StaticImage
          alt="Dreamcation's Challenge Before Pepper Cloud CRM"
          layout="constrained"
          placeholder="blured"
          src="../../images/v2/case-studies/case-3-challenge.png"
        />
      ),
      solutionHeader:
        "Pepper Cloud, a best CRM for Facebook leads, was a perfect fit for the requirements of Dreamcation.",
      solutions: [
        "Dreamcation could integrate Pepper Cloud CRM with their Facebook channel and could attend to the queries and messages right from the CRM.",
        "Sales representatives were notified of messages instantly and were able to send preset automatic replies. This reduced the response time to a great extent.",
        "Pepper Cloud CRM could automatically capture the details of leads from Facebook and consolidate lead information for easy access and follow-ups.",
        "The conversations could be assigned to other team members as and when required. This feature allowed the team to collaborate more effectively.",
        "Pepper Cloud offered a central hub through which the management could track the sales activities; monitor and manage the goals and data; and realign the sales efforts whenever necessary.",
      ],
      solutionQuotation: (
        <strong>
          "Facebook integration helped us handle queries in the shortest period
          of time"
        </strong>
      ),
      solutionImg: (
        <StaticImage
          alt="How Pepper Cloud CRM solved Dreamcation's Problem"
          layout="constrained"
          placeholder="blured"
          src="../../images/v2/case-studies/case-3-solution.png"
        />
      ),
      solutionFooter:
        "The Facebook integrated CRM, Pepper Cloud, assisted Dreamcation extensively in responding to queries and not letting any of the opportunities slip through the cracks.",
      featuresUsed: [
        {
          icon: messenger,
          title: "Facebook messenger integration",
          link: "/multichannel-integration-for-sales-marketing",
        },
        { icon: lead, title: "Lead and contact management", link: "" },
        {
          icon: pipeline,
          title: "Pipeline management",
          link: "/sales-pipeline-management-tool",
        },
      ],
      similarSuccessStories: [
        successStories["skypac-packaging"],
        successStories["tech-data"],
        successStories["cuscapi"],
      ],
    },
    "skypac-packaging": {
      seo: {
        title: "Xero CRM Integration Case Studies - SKYPAC | Pepper Cloud",
        description:
          "Pepper Cloud's Xero CRM integrations help SKYPAC scale up its sales & business by cutting down repetitive tasks. How? Learn from CRM Case Study",
        keywords: "Xero CRM Case Studies,Xero CRM integrations, CRM, CRM Case Studies",
      },
      title: titles["skypac-packaging"],
      description:
        "SKYPAC Packaging Pte. Ltd. offers quality packaging products at competitive prices to their customers. They envision being the preferred source of flexible packaging solutions in Singapore.",
      heroBg: case4hero,
      alt: "SKYPAC",
      name: names["skypac-packaging"],
      location: "Singapore",
      industry: "Packaging solutions",
      website: "https://www.skypacpackaging.com/",
      challenges: [
        "SKYPAC, a leading packaging solution company, was on a growth tangent and needed a powerful CRM solution that could help them scale up faster. They had a pipeline full of leads but they lacked coordination between sales, marketing, and accounting teams.",
        "Every time the sales team had to get information about the value of an account or had to generate sales quotations, they had to seek the assistance of the accounts department. The process was not systemised. As a result, the sales team didn’t have access to the required information at the right time to generate and send quotations, causing delays in closing deals.",
        "SKYPAC needed a comprehensive solution which could integrate with accounting software and is ancillary to its sales growth.",
      ],
      challegeQuotation: (
        <strong>
          "We needed a solution to help us streamline our sales process and
          increase work efficiency"
        </strong>
      ),
      challengeImg: (
        <StaticImage
          alt="SKYPAC's Challenge Before Pepper Cloud CRM"
          layout="constrained"
          placeholder="blured"
          src="../../images/v2/case-studies/case-4-challenge.png"
        />
      ),
      solutionHeader:
        "After thorough research on various CRM solutions, SKYPAC invested in Pepper Cloud, a CRM software that integrates with Xero.",
      solutions: [
        "Pepper Cloud CRM offered a scalable and customised solution for all the challenges faced by SKYPAC.",
        "The CRM offered a single, central location to store the critical information and allowed the management to define who can access what data, with data permissions.",
        "With a centralised database, SKYPAC no longer has to worry about losing customer conversation data and other information while transferring the data from one platform to another.",
        "The actionable information about sales, leads, and activities that are displayed in visual dashboards made it easier to focus on team productivity and assisted in defining future sales goals.",
        "The Xero CRM integrations of Pepper Cloud assisted SKYPAC in generating, sending, and managing sales quotations effortlessly. Additionally, it also allowed the team to track the status of the sent quotations.",
        "The 2-way sync of this Xero CRM saved valuable time and efforts of the team by auto-fetching the details of customers and making the process of generating sales quotations a lot more easier.",
      ],
      solutionQuotation: (
        <strong>
          "Pepper Cloud is easy-to-use, flexible, and scalable CRM software that
          cuts down repetitive tasks and increases our work efficiency"
        </strong>
      ),
      solutionImg: (
        <StaticImage
          alt="How Pepper Cloud CRM solved SKYPAC's Problem"
          layout="constrained"
          placeholder="blured"
          src="../../images/v2/case-studies/case-4-solution.png"
        />
      ),
      solutionFooter: "",
      featuresUsed: [
        { icon: access, title: "Access controls", link: "" },
        {
          icon: dashboard,
          title: "Dashboard",
          link: "/sales-dashboard",
        },
        {
          icon: xero,
          title: "Xero CRM integration",
          link: "/xero",
        },
      ],
      similarSuccessStories: [
        successStories["tech-data"],
        successStories["cuscapi"],
        successStories["eng-choon-enterprise"],
      ],
    },
    "tech-data": {
      seo: {
        title:
          "Lead Management Case Studies - Tech Data | Pepper Cloud",
        description:
          "How Tech Data manages leads and sales effectively with the #1 Sales CRM Pepper Cloud. Learn from CRM Case Study",
        keywords: "Lead Management Case Studies,Lead management, CRM Case Studies",
      },
      title: titles["tech-data"],
      description:
        "Tech Data is an end-to-end technology distributor with a focus on customers’ needs and goals with the objectivity of a true strategic partner.",
      heroBg: case5hero,
      alt: "Tech Data",
      name: names["tech-data"],
      location: "Singapore, Malaysia",
      industry: "IT services",
      website: "https://www.techdata.com/",
      challenges: [
        "Before adopting Pepper Cloud CRM as the company’s CRM, Tech Data was using spreadsheets to manage its leads and customer data.  The manual error was a persistent problem. But alongside, it also resulted in delays in follow-ups and missing opportunities. The lack of visibility into the sales data and less collaboration with other departments led to a lack of resource management and reduced team productivity. Although there were lots of sales activities taking place, they needed a sales tool to define and drive goal-oriented sales activities.",
        "In essence, Tech Data was looking for a scalable, customisable, and the best CRM solution for its sales reps and for its rapidly expanding business.",
      ],
      challegeQuotation: (
        <strong>
          "Team collaboration, accurate sales forecasting, and effective
          resource management were the major challenges we encountered"
        </strong>
      ),
      challengeImg: (
        <StaticImage
          alt="Tech Data's Challenge Before Pepper Cloud CRM"
          layout="constrained"
          placeholder="blured"
          src="../../images/v2/case-studies/case-5-challenge.png"
        />
      ),
      solutionHeader:
        "With heavily integrated Pepper Cloud CRM, Tech Data could easily capture leads and manage its entire sales process effectively.",
      solutions: [
        <div>
          Tech Data started to automatically capture leads from multiple landing
          pages through the <Link to="/lead-capture-generation-webform">embedded web forms</Link> of
          Pepper Cloud CRM. The sales team would receive instant notifications
          about new leads. This reduced the response time and ensured no
          opportunities were missed.
        </div>,
        "The custom fields in Pepper Cloud CRM allowed the team to customise the business modules as per their requirements and eased the process of recording the data into the sales CRM.",
        "With end-to-end sales pipeline management, Tech Data could successfully organise, track, and streamline its sales process, and design sales strategy.",
        "With the sales CRM features like winning probability and weighted average, Tech data could accurately assess its financial health and predict future revenue.",
      ],
      solutionQuotation: (
        <strong>
          "Pepper Cloud enhanced our productivity, optimised the sales process
          and helped manage our sales pipeline"
        </strong>
      ),
      solutionImg: (
        <StaticImage
          alt="How Pepper Cloud CRM solved Tech Data's Problem"
          layout="constrained"
          placeholder="blured"
          src="../../images/v2/case-studies/case-5-solution.png"
        />
      ),
      solutionFooter: "",
      featuresUsed: [
        {
          icon: mailchimp,
          title: "Mailchimp integration",
          link: "/integration",
        },
        {
          icon: webform,
          title: "Web forms",
          link: "/lead-capture-generation-webform",
        },
        { icon: lead, title: "Lead management", link: "" },
      ],
      similarSuccessStories: [
        successStories["cuscapi"],
        successStories["eng-choon-enterprise"],
        successStories["the-supreme"],
      ],
    },
    cuscapi: {
      seo: {
        title:
          "Activity & Lead Management Case Studies - Cuscapi | Pepper Cloud",
        description:
          "Cuscapi formulates its sales growth with Singapore's Best Sales CRM - Pepper Cloud's Activity & Lead Management Functionality. How? Learn from CRM Case Study.",
        keywords:
          "Activity Management Case Studies,Activity management (calendar), Lead management, CRM Case Studies",
      },
      title: titles["cuscapi"],
      description:
        "Cuscapi offers business management solutions, information technology security solutions, consulting services and contact centre outsourcing services for businesses across various industries.",
      heroBg: case6hero,
      alt: "Cuscapi",
      name: names["cuscapi"],
      location: "Malaysia, Singapore",
      industry: "Management services",
      website: "https://cuscapi.com/",
      challegeQuotation: (
        <strong>
          "We needed a sales CRM to help us manage our business in 6 countries"
        </strong>
      ),
      challenges: [
        "Cuscapi needed a scalable CRM that works shoulder-to-shoulder with it to structure sales strategy and monetise its sales across 6 countries. They couldn’t risk losing information and simultaneously, needed a clear overview of their leads and prospects.",
        "Cuscapi was using a spreadsheet model to manage leads and their customer data. With the team spread out across different countries, they struggled with next to no visibility between the teams and management. These issues resulted in coordination challenges, missed opportunities and slowed down the sales process.",
        "It was critical for Cuscapi to find an easy-to-use, cloud-based, and best sales pipeline management tool that formalises its sales structure, manages its business operations across various countries, yet provides the required information in real-time.",
      ],
      challengeImg: (
        <StaticImage
          alt="Cuscapi's Challenge Before Pepper Cloud CRM"
          layout="constrained"
          placeholder="blured"
          src="../../images/v2/case-studies/case-6-challenge.png"
        />
      ),
      solutionHeader:
        "Cuscapi realised that Pepper Cloud CRM can be the solution for all the issues it was encountering in its day-to-day operations.",
      solutions: [
        "Pepper Cloud CRM provided a central location to store the lead details and customer data and empowered the team with easy access to the information.",
        "Cuscapi could define the data permissions for its team by defining the roles, profiles, and permissions for its users. This provides only required information to the sales representatives and yet protects the other data.",
        "After onboarding with Pepper Cloud CRM, Cuscapi created multiple sales pipelines, tailored to its requirements and structured a hassle-free sales funnel for its leads and opportunities. This helped in addressing the gaps in the sales process.",
        "The intuitive sales dashboard assist Cuscapi in analysing key performance metrics and gaining action-oriented insights for their sales plan.",
      ],
      solutionQuotation: (
        <strong>
          "The strong sales pipeline management and robust user management
          system help us to manage our business operations"
        </strong>
      ),
      solutionImg: (
        <StaticImage
          alt="How Pepper Cloud CRM solved Cuscapi's Problem"
          layout="constrained"
          placeholder="blured"
          src="../../images/v2/case-studies/case-6-solution.png"
          // fit="contain"
        />
      ),
      solutionFooter: "",
      featuresUsed: [
        { icon: calendar, title: "Activity management (calendar)", link: "" },
        { icon: lead, title: "Lead management", link: "" },
        {
          icon: pipeline,
          title: "Custom sales pipeline",
          link: "/sales-pipeline-management-tool",
        },
      ],
      similarSuccessStories: [
        successStories["eng-choon-enterprise"],
        successStories["the-supreme"],
        successStories["dreamcation"],
      ],
    },
  };

  const scrollAboutList = () => {
    if (
      document.querySelector("#scrollIndicator")?.getBoundingClientRect()
        .top === 100
    ) {
      let winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      let height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      let scrolled = (winScroll / height) * 100;
      document.getElementById("scrollIndicator").style.height =
        scrolled * 10 + "px";
    } else if (
      document.querySelector("#scrollIndicator")?.getBoundingClientRect().top >
      100
    ) {
      document.getElementById("scrollIndicator").style.height = "100px";
    } else if (
      document.querySelector("#scrollIndicator")?.getBoundingClientRect().top <
      100
    ) {
      document.getElementById("scrollIndicator").style.height = "500px";
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollAboutList);
    const currCompany = location.pathname.split("/")[2];
    setState({ loading: false, company: currCompany });
  }, []);

  if (loading || !company) {
    return (
      <Box display="flex" alignItems="ceter" justifyConternt="center">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (!caseStudies[company]) {
    return <NotFoundPage />;
  }

  const caseStudy = caseStudies[company];

  return (
    <React.Fragment>
      <SEO
        description={caseStudy.seo.description}
        keywords={caseStudy.seo.keywords}
        pathname={successStories[company].link}
        title={caseStudy.seo.title}
      />
      <Grid
        alignItems="center"
        className={classes.bannerBackground}
        container
        justify="center"
      >
        <Grid item md={6} sm={12}>
          <Box display="flex" justifyContent={{ sm: "center", md: "flex-end" }}>
            <Box maxWidth={620} p={1} pr={{ md: 8, lg: 10 }}>
              <HeaderTypography
                alignItems="center"
                className={classes.accordionTitle}
                component="h1"
                display="flex"
                fontSize={40}
                fontWeight={700}
                justifyContent={{ sm: "center", md: "flex-start" }}
                lineHeight={1.4}
                mb={2}
                mt={0}
              >
                {caseStudy.title}
              </HeaderTypography>
              <ParagraphTypography
                component="p"
                mt={0}
                pr={{ md: 4 }}
                textAlign={{ sm: "center", xs: "center" }[width] || "left"}
              >
                {caseStudy.description}
              </ParagraphTypography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box py={{ md: 4 }} textAlign="right">
            <img
              alt={caseStudy.alt}
              className={classes.img}
              src={caseStudy.heroBg}
            />
          </Box>
        </Grid>
      </Grid>
      <Box md={10}>
        <Divider light />
      </Box>

      <Container>
        <Box
          alignItems="flex-start"
          component={Grid}
          container
          justify="center"
          textAlign={{ sm: "center", xs: "center" }[width] || "left"}
          py={{ md: 8 }}
          spacing={5}
        >
          <Hidden smDown>
            <Grid
              item
              xs={3}
              lg={3}
              sm={3}
              md={3}
              className={classes.aboutList}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                {caseStudy.name}
                <Box py={6}>
                  <HeaderTypography
                    component="p"
                    fontFamily="Source Sans Pro"
                    fontSize={18}
                    mb={0}
                    mt={0}
                  >
                    Location:
                  </HeaderTypography>
                  <ParagraphTypography
                    fontFamily="Source Sans Pro"
                    fontSize={16}
                    mb={6}
                    mt={0}
                  >
                    {caseStudy.location}
                  </ParagraphTypography>
                  <HeaderTypography
                    component="p"
                    fontFamily="Source Sans Pro"
                    fontSize={18}
                    mb={0}
                    mt={0}
                  >
                    Industry:
                  </HeaderTypography>
                  <ParagraphTypography
                    fontFamily="Source Sans Pro"
                    fontSize={16}
                    mt={0}
                    mb={6}
                  >
                    {caseStudy.industry}
                  </ParagraphTypography>
                  <HeaderTypography
                    fontFamily="Source Sans Pro"
                    component="p"
                    fontSize={18}
                    mb={0}
                    mt={0}
                  >
                    Website:
                  </HeaderTypography>
                  <ParagraphTypography
                    fontFamily="Source Sans Pro"
                    fontSize={16}
                    mt={0}
                    mb={0}
                  >
                    <a href={`${caseStudy.website}`} target="_blank">
                      {caseStudy.website}
                    </a>
                  </ParagraphTypography>
                </Box>
              </Box>
              <Box
                className={classes.aboutListIndicator}
                id="scrollIndicator"
              ></Box>
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={12} md={9} lg={9}>
            <HeaderTypography component="p" fontSize={24} mb={0} mt={0}>
              The challenge
            </HeaderTypography>
            <Box component="h2" fontSize={20} mt={1}>
              {caseStudy.challegeQuotation}
            </Box>
            {caseStudy.challenges.map((challenge) => (
              <ParagraphTypography>{challenge}</ParagraphTypography>
            ))}
            <Box component="h2" px={{ md: 10 }} py={{ md: 2 }}>
              {caseStudy.challengeImg}
            </Box>
            <Box my={{ md: 8 }}>
              <Divider light />
            </Box>
            <HeaderTypography component="p" fontSize={24} mb={0} mt={0}>
              The solution
            </HeaderTypography>
            <Box component="h2" fontSize={18} mt={1}>
              {caseStudy.solutionQuotation}
            </Box>
            <ParagraphTypography>
              {caseStudy.solutionHeader}
            </ParagraphTypography>
            <ul>
              {caseStudy.solutions.map((solution) => (
                <li>
                  <ParagraphTypography>{solution}</ParagraphTypography>
                </li>
              ))}
            </ul>
            <Box px={{ md: 10 }} pt={2}>
              {caseStudy.solutionImg}
            </Box>

            <ParagraphTypography>
              {caseStudy.solutionFooter}
            </ParagraphTypography>
          </Grid>
        </Box>
      </Container>

      <Divider light />

      <Box
        className={classes.featureBG}
        px={2}
        py={{ sm: 2, xs: 2 }[width] || 8}
      >
        <Container>
          <Box alignItems="center" display="flex" flexDirection="column" pt={0}>
            <HeaderTypography
              component="p"
              maxWidth={726}
              mb={4}
              mt={0}
              textAlign="center"
            >
              Features used
            </HeaderTypography>
          </Box>
          <Grid container justify="center" spacing={4}>
            {caseStudy.featuresUsed.map((feature) => (
              <Grid
                component={Box}
                item
                md={4}
                sm={4}
                textAlign="center"
                xs={12}
              >
                <div className={classes.featureItem}>
                  <Box
                    alt={feature.title}
                    className={classes.logo}
                    component="img"
                    src={feature.icon}
                    width={100}
                  />
                  <Box
                    color="text.secondary"
                    component="h3"
                    fontSize={24}
                    fontWeight="600"
                  >
                    {feature.link ? (
                      <Link to={feature.link}>{feature.title}</Link>
                    ) : (
                      <div>{feature.title}</div>
                    )}
                  </Box>
                </div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Divider light />
      <Box py={{ md: 8 }}>
        <Container>
          <Box alignItems="center" display="flex" flexDirection="column">
            <HeaderTypography
              component="p"
              maxWidth={726}
              mb={4}
              mt={0}
              textAlign="center"
            >
              Similar success stories
            </HeaderTypography>
          </Box>
          <Grid container justify="center" spacing={4}>
            {caseStudy.similarSuccessStories.map((story) => (
              <Grid
                component={Box}
                item
                md={4}
                sm={4}
                textAlign="center"
                xs={12}
              >
                <div className={classes.featureItem}>
                  <Box height={70}>{story.icon}</Box>
                  <ParagraphTypography>{story.title}</ParagraphTypography>
                </div>
                <Link to={story.link} className={classes.readMoreLink}>
                  Read More
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box
        className={classes.requestDemoRoot}
        px={2}
        py={{ sm: 10, xs: 8 }[width] || 12}
        display="flex"
        justifyContent={"center"}
        flexDirection="column"
        alignItems={"center"}
      >
        <HeaderTypography
          color="common.white"
          fontSize={35}
          m={0}
          textAlign="center"
          mb={4}
          component="h3"
        >
          Let’s build your sales success together!
        </HeaderTypography>
        <ParagraphTypography
          color="common.white"
          fontSize={20}
          mt={0}
          textAlign="center"
        >
          Discover how you can build your business, boost your sales and win
          your customers with the all-in-one sales CRM.
        </ParagraphTypography>
        <Box mt={6} textAlign="center">
          <WhiteButton
            color="primary"
            component={Link}
            size="large"
            to="/contact"
            variant="contained"
          >
            Get Started
          </WhiteButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Company;
